#loading {
	background-color: #fff;
	opacity: 0.8;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	right: 0;
	z-index: 4;
	width: 100%;
	height: 100%;

	&.hide {
		display: none;
	}
	&.show {
		display: flex;
		justify-content: center;
	}
	.lds-ripple {
		margin-top: calc(#{$module} * 5);
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		div {
			position: absolute;
			border: 4px solid map-get($theme-colors, 'primary');
			opacity: 1;
			border-radius: 50%;
			animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			&:nth-child(2) {
				animation-delay: -0.5s;
			}
		}
	}
	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}
}
