.action-button {
	border: none;
	background-color: transparent;
	align-self: baseline;
	&.add {
		color: map-get($theme-colors, 'ds-btn-danger');
	}
	&.edit,
	&.save,
	&.see {
		color: #3fcb72;
	}
	&.delete {
		color: map-get($theme-colors, 'primary');
	}
}
