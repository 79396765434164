.login-nav {
	padding: calc(#{$module} / 4) calc(#{$module} * 1);
	border-radius: 10px;
	height: 8vh;

	display: flex;
	justify-content: space-between;
	align-items: center;
	figure {
		height: 50px;
		width: 120px;
		overflow: hidden;
		margin: 0;
		img {
			height: 100%;
			object-fit: contain;
			object-position: left;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		a {
			display: flex;
			color: map-get($theme-colors, 'ds-back');
			font-family: $font-text;
			margin: 0 0 0 calc(#{$module} / 3);
			&:hover {
				text-decoration: none;
				color: map-get($theme-colors, 'secondary');
			}
		}
	}
}
