.section-title {
	color: map-get($theme-colors, 'ds-text-white');
	background-color: map-get($theme-colors, 'ds-main');
	border-radius: 10px;
	width: 100%;
	padding: $module;
	position: relative;
	margin-bottom: calc(#{$module} * 2);
	text-align: left;
	margin-top: calc(#{$module} * 1);
	box-shadow: 0px 0px 15px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.9);

	img {
		position: absolute;
		height: 130%;
		width: 250px;
		right: 0;
		bottom: calc(#{$module} / 2);
	}
	p {
		max-width: 70%;
	}
}
.dashboard-user {
	color: map-get($theme-colors, 'ds-main');
	span {
		font-weight: $text-semibold;
	}
}
