.add-form {
	margin: $module auto;
	width: 40%;
	display: flex;
	flex-direction: column;
	input {
		margin-bottom: calc(#{$module} / 2);
	}
}

.editing {
	padding: calc(#{$module} * 2) 0;
}
.edit-group {
	margin: 0;
	flex-direction: column;
	padding: calc(#{$module} * 0.5);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background-color: white;
	margin: 12px 0;
	border-radius: 5px;
	figure {
		width: 200px;
		height: 100px;
		margin: 0 calc(#{$module} * 2) 0 0;
		overflow: hidden;
		background-color: map-get($theme-colors, 'light-grey');
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	p {
		margin: 0 calc(#{$module} * 2) 0 0;
	}
	.my-btn {
		&.secondary {
			margin-right: calc(#{$module} / 8);
		}
	}
	&.categories {
		flex-direction: column;
		> * {
			width: 100%;
			display: flex;
		}

		.maq-cat-title {
			text-align: left;
			font-weight: $text-semibold;
			margin: 0;
			margin-bottom: 12px;
		}
	}
}
.features {
	display: flex;
	flex-wrap: wrap;
	padding: 12px 0;

	.each-feat {
		@include items-grid(4, $module);
		padding: 8px;
		border-radius: 10px;
		background-color: map-get($theme-colors, 'ds-btn-secondary');
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		svg {
			align-self: flex-end;
			cursor: pointer;
			width: 15px;
			height: 15px;
		}
	}
}
