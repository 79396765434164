#posts-screen {
	h2 {
		margin: 0;
		margin-bottom: calc(#{$module} * 0.5);
	}
	.user {
		color: map-get($theme-colors, 'secondary');
	}
	.empty-posts {
		margin-top: calc(#{$module} * 1);
		background-color: map-get($theme-colors, 'light-grey');
		padding: calc(#{$module} * 1) 0;
		text-align: center;
		color: map-get($theme-colors, 'text');
		border-radius: 5px;
	}

	.all-posts {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: calc(#{$module} * 2);
	}
}
