.add-text {
	display: flex;
	flex-direction: column;
	.my-btn {
		align-self: flex-end;
	}
}
.add-image {
	width: 100%;
	display: flex;
	flex-direction: column;
	.muestra {
		background-color: white;
		padding: calc(#{$module} / 2);
		border-radius: 10px;
		color: map-get($theme-colors, 'ds-main');
		margin-bottom: calc(#{$module} / 2);
		width: fit-content;
		button {
			margin-top: calc(#{$module} / 2);
		}
		figure {
			width: 250px;
			height: 0;
			padding-bottom: 60%;
			overflow: hidden;
			margin-bottom: 0;
			border-radius: 5px;
			position: relative;
			img {
				position: absolute;
				left: 0;
				top: 0;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}
