#messages-dashboard {
	.messages {
		display: flex;
		flex-wrap: wrap;
	}
}
.message-content {
	margin-top: 24px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
	padding: $module;
	background-color: white;
	border-radius: 20px;
	cursor: inherit;
	box-shadow: 0px 0px 5px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.9);
	&:hover {
		box-shadow: 0px 0px 8px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.7);
	}
	.user-info {
		display: flex;
		flex-direction: column;
		background-color: map-get($theme-colors, 'ds-back');
		padding: 24px;
		border-radius: 20px;
		margin-bottom: 24px;
		button {
			margin-top: 12px;
		}
	}
	.message {
		margin-bottom: 24px;
	}
}
