.login-container {
	background-color: map-get($theme-colors, 'ds-back');
	border-radius: 30px;
	height: 98vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	figure {
		width: 200px;
		height: 200px;
		overflow: hidden;
		position: relative;
		margin-top: calc(#{$module} * 3);
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}
	.login {
		width: 350px;
		margin: 0 auto;
		height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.my-btn {
			margin: calc(#{$module} / 2) auto;
		}
	}
	.copyright {
		margin-top: auto;
	}
}
