#add-posts-screen {
	.features {
		padding: calc(#{$module} / 2) 0;
		.each-feat {
			@include items-grid(3, $module);
		}
	}
	.post-btn-group {
		margin-bottom: calc(#{$module} * 1);
		display: flex;
		width: 100%;
		button {
			margin-right: calc(#{$module} / 2);
			&.danger {
				justify-self: flex-end;
			}
		}
	}
	.icon-preview {
		width: 80px !important;
		height: 80px !important;
		overflow: hidden;
		margin-bottom: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
	.icon-group {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		.file-group {
			flex-grow: 1;
			margin-right: 8px;
			input {
				margin-bottom: 0 !important;
			}
		}
	}
	.edit-post {
		height: 90vh;
		overflow: hidden;
		display: flex;
		width: 100%;
		.edit-area {
			width: calc((100% / 12) * 6);
			overflow: scroll;
			padding-right: calc(#{$module} * 1);
			.add-post {
				display: flex;
				align-items: stretch;
				width: 100%;
				margin: calc(#{$module} * 1) 0;
				.file-group {
					display: flex;
					flex-direction: column;
					margin-bottom: calc(#{$module} * 1);

					.file-input {
						outline: none;
						color: map-get($theme-colors, 'text');
						font-weight: $text-light;
						@include font-line-height(10px);
						margin-bottom: calc(#{$module} / 2);
						border-bottom: none;
						overflow: hidden;
						&::-webkit-file-upload-button {
							visibility: hidden;
						}
						&:focus {
							border: none;
						}
						&::before {
							content: 'Elegir archivo...';
							background: white;
							color: map-get($theme-colors, 'dark-grey');
							border: 1px solid map-get($theme-colors, 'dark-grey');
							height: 25px;
							padding: calc(#{$module} / 6) calc(#{$module} / 4);
							outline: none;
							white-space: nowrap;
							cursor: pointer;
							font-size: 10px;
							width: 100%;
						}
					}
				}
			}
		}
		.view-area {
			width: calc((100% / 12) * 6);
			overflow: scroll;
			padding: calc(#{$module} * 1);
			border-radius: 20px;
			background-color: white;
			.titles-btn {
				background-color: map-get($theme-colors, 'ds-back');
				padding: calc(#{$module} / 2);
				border-radius: 10px;
				color: map-get($theme-colors, 'ds-main');
				h1 {
					@include font-line-height(24px);
				}
				h2 {
					@include font-line-height(20px);
					color: map-get($theme-colors, 'ds-main');
					margin-top: $module;
					margin-bottom: 0;
				}
			}
			.features-view {
				padding: calc(#{$module} * 1) 0;
				// margin-top: $module;
			}
			.each-text {
				background-color: map-get($theme-colors, 'ds-back');
				padding: calc(#{$module} / 2);
				border-radius: 10px;
				color: map-get($theme-colors, 'ds-main');
				margin-top: calc(#{$module} / 3);
				button {
					margin-top: calc(#{$module} / 2);
				}
			}
			.each-image {
				background-color: map-get($theme-colors, 'ds-back');
				padding: calc(#{$module} / 2);
				border-radius: 10px;
				color: map-get($theme-colors, 'ds-main');
				margin-top: calc(#{$module} / 3);
				button {
					margin-top: calc(#{$module} / 2);
				}
				figure {
					width: 250px;
					height: 0;
					padding-bottom: 25%;
					overflow: hidden;
					margin-bottom: 0;
					border-radius: 5px;
					position: relative;
					img {
						position: absolute;
						left: 0;
						top: 0;
						object-fit: cover;
						object-position: center;
					}
				}
			}
		}
	}
}
